<template>
  <b-table :items="clients"
            :fields="macauth_fields"
            class="m-0"
            striped
            responsive
            primary-key="description">
    <template v-slot:head(buttons)>
      <b-button block variant="outline-success"
                :id="'button-create-client-' +  bcd.name"
                @click="createClient(bcd)">
        <netvs-icon icon="create"></netvs-icon>
      </b-button>
      <b-tooltip :target="'button-create-client-' +  bcd.name" triggers="hover"
                  variant="success" placement="left">
        {{ $t('components.mac_auth_card.add_client') }}
      </b-tooltip>
    </template>
    <template v-slot:cell(wpa_key)="data">
            <span v-if="data.item.wpa_key !== null">
              <b-button-group class="fullwidth">
                <b-button @click="showQR(data.item)"
                          :id="'show-wpa-key-' + bcd.name + '-' + data.index+'-qr'"
                          variant="outline-primary"
                          tabindex="0" href="#">
                  <netvs-icon icon="eye"></netvs-icon>
                </b-button>
                <b-tooltip :target="'show-wpa-key-' + bcd.name + '-' + data.index+'-qr'"
                            triggers="hover" variant="primary" placement="bottom">
                  {{ $t('components.mac_auth_card.show_secret') }}
                </b-tooltip>
              </b-button-group>
            </span>
      <span v-else class="text-center"><p><netvs-icon icon="eye_slash">
              </netvs-icon></p></span>
    </template>
    <template v-slot:cell(last_login_date)="data">
      {{ formatDate(data.item.last_login_date) }}
    </template>
    <template v-slot:cell(mac_addr)="data">
      <code>{{ data.item.mac_addr }}</code>
    </template>
    <template v-slot:cell(buttons)="data">
      <b-button-group class="fullwidth">
        <b-button variant="outline-primary"
                  :id="'button-edit-client-' + bcd.name + '-' + data.index"
                  @click="editClient(data.item)">
          <netvs-icon icon="edit"></netvs-icon>
        </b-button>
        <b-tooltip :target="'button-edit-client-' + bcd.name + '-' + data.index"
                    triggers="hover" variant="primary" placement="top">
          {{ $t('components.mac_auth_card.edit_client') }}
        </b-tooltip>
        <b-button variant="outline-danger"
                  :id="'button-delete-client-' + bcd.name + '-' + data.index"
                  @click="deleteClient(data.item)">
          <netvs-icon icon="delete"></netvs-icon>
        </b-button>
        <b-tooltip :target="'button-delete-client-' + bcd.name + '-' + data.index"
                    triggers="hover" variant="danger" placement="top">
          {{ $t('components.mac_auth_card.remove_client') }}
        </b-tooltip>
      </b-button-group>
    </template>
  </b-table>
</template>

<script>

import dateutil from '../util/dateutil'

export default {
  name: 'MacAuthTable',
  components: {},
  computed: {
    macauth_fields() {
      return [
        {
          key: 'description',
          label: this.$t('system.description')
        },
        {
          key: 'mac_addr',
          label: this.$t('system.mac_address'),
          sortable: true
        },
        {
          key: 'wpa_key',
          label: this.$t('components.mac_auth_card.wpa2_key')
        },
        {
          key: 'last_login_date',
          label: this.$t('components.mac_auth_card.last_login'),
          sortable: true
        },
        {
          key: 'last_login_node_info',
          label: this.$t('components.mac_auth_card.last_login_node'),
          sortable: true
        },
        'buttons'
      ]
    }
  },
  props: {
    bcd: {
      type: Object,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    createClient: {
      type: Function,
      required: true
    },
    deleteClient: {
      type: Function,
      required: true
    },
    editClient: {
      type: Function,
      required: true
    },
    showQR: {
      type: Function,
      required: true
    }
  },
  methods: {
    formatDate(value) {
      if (value == null) {
        return 'N/A'
      }
      return dateutil.format_date(new Date(Date.parse(value)))
    }
  }
}
</script>

<style scoped>

</style>
