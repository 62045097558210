<template>
  <div class="dhcp-leases">
    <h1>{{ $t('views.dhcp_leases.leases.active_leases', { name: $route.params.bcd_name }) }}</h1>
    <b-alert variant="info" show>
      {{ $t('views.dhcp_leases.leases.static_warning')}}
    </b-alert>
    <Loading :data="leases">
      <b-table responsive striped :items="leases" :busy="!leases" :fields="table_fields">
        <template v-slot:cell(ip)="data">
          <code>{{ data.item.ip }}</code>
        </template>
        <template v-slot:cell(mac)="data">
          <code>{{ data.item.mac }}</code>
        </template>
      </b-table>
    </Loading>
  </div>
</template>

<script>
import LeaseService from '@/api-services/dhcp_leases.service'
import Loading from '@/components/Loading'

export default {
  name: 'DHCPLeasesOverview',
  components: { Loading },
  computed: {
    table_fields() {
      return [
        {
          key: 'ip',
          label: this.$t('system.ip_address')
        },
        {
          key: 'mac',
          label: this.$t('system.mac_address')
        },
        {
          key: 'name',
          label: this.$t('views.dhcp_leases.leases.transmitted_hostname'),
          sortable: true
        }
      ]
    }
  },
  data() {
    return {
      leases: null
    }
  },
  async mounted() {
    this.leases = (await LeaseService.getLeases(this.$store.state, this.$route.params.bcd_name)).data
  }
}
</script>

<style scoped>

</style>
