<template>
  <b-tr v-if="block.type == 'free'" variant="success" class="free-block">
    <b-td colspan="4"/>
    <b-td>
      <b-badge href="#" @click="create_item_func('A', block.first)" variant="success">
        <span v-if="block.space > 1">{{ block.space }}</span>
        <span v-else>{{ $t('components.bcd_free_address_block.one') }}</span>
        {{ $tc('components.bcd_free_address_block.free_address', block.space) }}
      </b-badge>
    </b-td>
    <b-td colspan="2"/>
  </b-tr>
  <b-tr :key="'block_lower_' + item.data + '_' + index" v-else variant="warning" class="free-block">
    <b-td colspan="4"></b-td>
    <b-td>
      <b-badge href="#" @click="create_item_func('A', block.first)" variant="warning">
        <span v-if="block.space > 1">{{ block.space }}</span>
        <span v-else>{{ $t('components.bcd_free_address_block.one') }}</span>
        {{ $tc('components.bcd_free_address_block.reserved_address', block.space) }}
      </b-badge>
    </b-td>
    <b-td colspan="2"/>
  </b-tr>
</template>

<script>
export default {
  name: 'BCDFreeAddressBlock',
  props: {
    block: {
      required: true
    },
    index: {
      required: true
    },
    item: {
      required: true
    },
    create_item_func: {
      required: true
    }
  }
}
</script>

<style>
.free-block td {
  padding: 0.25rem;
}
</style>
