<template>
  <div>
    <BCDDetails :bcd_name="$route.params.bcd_name" :key="$route.params.bcd_name"/>
    <b-tabs justified @input="updateRoute">
      <b-tab v-for="tab in tabs" :key="tab.route_name" :active="$route.params.system==tab.system" :title="$t(tab.title_key)" lazy>
        <div style="min-height: 50vh; padding-top: 15px">
          <component :is="tab.component" :bcd_name="$route.params.bcd_name" :use_cards="false"/>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BCDRecords from '@/views/dnsvs/BCDRecords.vue'
import BCDDetails from '@/components/BCDDetails.vue'
import MacAuth from '@/views/macauth/MACAuth.vue'
import NetdocBCD from '@/views/nd/NetdocBCD.vue'
import BCDRights from '@/components/BCDRights.vue'
import Leases from '@/views/dhcp_leases/Leases.vue'

export default {
  name: 'GenericBCDView',
  components: {
    NetdocBCD,
    MacAuth,
    BCDRecords,
    BCDDetails,
    BCDRights
  },
  data() {
    return {
      bcd: null,
      tabs: [
        {
          title_key: 'system.permissions',
          system: 'cntl',
          component: BCDRights
        },
        {
          title_key: 'system.dnsvs',
          system: 'dnsvs',
          component: BCDRecords
        },
        {
          title_key: 'system.macauth',
          system: 'macauth',
          component: MacAuth
        },
        {
          title_key: 'system.netdoc',
          system: 'netdoc',
          component: NetdocBCD
        },
        {
          title_key: 'system.dhcp_leases',
          system: 'dhcp-leases',
          component: Leases
        }
      ]
    }
  },
  methods: {
    mounted() {
      this.$store.commit('setNavigationRefreshHandle', {variant: this.$route.params.system})
    },
    updateRoute(tabIndex) {
      this.$store.commit('setNavigationRefreshHandle', {variant: this.tabs[tabIndex].system})
      const path = `/${this.tabs[tabIndex].system}/bcds/${this.$route.params.bcd_name}`
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    }
  },
}
</script>

<style scoped>

</style>
