<template>
  <div>
    <code class="mr-2">{{ subnet.cidr }}</code>
    <b-button variant="light" v-if="subnet.type === '4'" v-b-modal:show="'info-modal-v4-' + subnet.cidr">
      <netvs-icon icon="info"></netvs-icon>
    </b-button>
    <b-button variant="light" v-if="subnet.type === '6'" v-b-modal:show="'info-modal-v6-' + subnet.cidr">
      <netvs-icon icon="info"></netvs-icon>
    </b-button>
    <b-modal v-if="subnet.type === '4'" :id="'info-modal-v4-' + subnet.cidr" ok-only centered
             :title="$tc('system.subnet', 1) + ' ' + subnet.cidr">
      <h6>{{ $t('components.subnet_info.subnet_info') }}</h6>
      <b-table-simple>
        <b-tr>
          <b-th>{{ $tc('system.subnet', 1) }} ({{ $t('components.subnet_info.cidr_notation') }})</b-th>
          <b-td><code>{{ subnet.cidr }}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.first_address') }}</b-th>
          <b-td><code>{{ ip_net_get_first(subnet.cidr) }}</code></b-td>
        </b-tr>
        <b-tr v-if="ip_num_addr(subnet.cidr) > 3">
          <b-th>{{ $t('components.subnet_info.first_usable_address') }}</b-th>
          <b-td><code>{{ int_to_ip(ip_to_int(ip_net_get_first(subnet.cidr)) + 1) }}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.last_address') }}</b-th>
          <b-td><code>{{ ip_net_get_last(subnet.cidr) }}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.subnet_mask') }}</b-th>
          <b-td><code>{{ ip_net_get_mask(subnet.cidr) }}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.addresses_in_network') }}</b-th>
          <b-td>2<sup>32-{{ subnet.cidr.split('/')[1] }}</sup> = {{ ip_num_addr(subnet.cidr) }}</b-td>
        </b-tr>
      </b-table-simple>
    </b-modal>
    <b-modal v-if="subnet.type === '6'" :id="'info-modal-v6-' + subnet.cidr" ok-only centered :title="$tc('system.subnet', 1) + ' ' + subnet.cidr">
      <b-table-simple>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.expanded_ipv6')}}</b-th>
          <b-td><code>{{expandIP(subnet)}}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.minimized_ipv6')}}</b-th>
          <b-td><code>{{minimizeIP(subnet)}}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('components.subnet_info.first_v6_address')}}</b-th>
          <b-td><code>{{ipv6_net_get_info(subnet).start}}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $tc('components.subnet_info.last_v6_address')}}</b-th>
          <b-td><code>{{ipv6_net_get_info(subnet).end}}</code></b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $tc('components.subnet_info.addresses_in_network')}}</b-th>
          <b-td><code>{{ipv6_net_get_info(subnet).size}}</code></b-td>
        </b-tr>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
import ipaddress from '@/util/ipaddress'
import {ipv6} from 'address'

export default {
  name: 'SubnetInfo',
  props: {
    subnet: {
      required: true
    }
  },
  methods: {
    ipv6,
    ip_net_get_first: ipaddress.ip_net_get_first,
    ip_net_get_last: (net) => ipaddress.ip_net_get_last(net),
    ip_net_get_mask: ipaddress.ip_net_get_mask,
    ip_num_addr: (subnet) => ipaddress.ip_num_addr(subnet),
    ip_to_int: ipaddress.ip_to_int,
    int_to_ip: ipaddress.int_to_ip,
    ipv6_net_get_info(subnet) {
      const splitted = subnet.cidr.split('/')
      return ipaddress.ipv6_range(splitted[0], splitted[1], 128)
    },
    expandIP(subnet) {
      const splitted_subnet = subnet.cidr.split('/')
      return ipaddress.normalize_ipv6(splitted_subnet[0]) + '/' + splitted_subnet[1]
    },
    minimizeIP(subnet) {
      const splitted_subnet = subnet.cidr.split('/')
      return ipaddress.abbreviate_ipv6(splitted_subnet[0]) + '/' + splitted_subnet[1]
    }
  }
}
</script>

<style scoped>

</style>
