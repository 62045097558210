<template>
  <div id="bcd-records">
    <Loading :data="bcd">
      <template v-if="bcd"> <!-- WHY?! JUST WHYYYY??? IT WORKS EVERYWHERE ELSE!? -->
        <b-row>
          <b-col cols="2" order="1" class="d-none d-lg-block bottom-space">
            <Loading style="top:75px" class="shadow sticky-top" :data="loaded" :class="{'py-3': !loaded}">
              <b-list-group v-if="record_types" v-b-scrollspy="{offset: 250}" class="overflow-hidden">
                <b-list-group-item href="#reserved" @click="scrollIntoView($event,1, 'reserved')"
                                   class="text-primary" v-if="subnets.length > 0">
                  <b-badge variant="secondary" class="mr-2" pill>{{ reservedAddrCount }}</b-badge>
                  <div class="d-inline-block">
                    <transition name="squish">
                      <netvs-icon v-if="collapse_states[1]" icon="navigation_list_marker"></netvs-icon>
                    </transition>
                    {{ $t('views.dnsvs.bcd_records.reserved_addresses') }}
                    <b-spinner small v-show="loading_states[1]" variant="primary"/>
                  </div>
                </b-list-group-item>
                <b-list-group-item v-for="(record_type, index) in record_types" :key="record_type"
                                   :href="'#' + record_type + '-records'"
                                   @click="scrollIntoView($event,2+index, record_type+'-records')"
                                   class="text-primary">
                  <b-badge variant="secondary" class="mr-2" pill>{{ records[record_type].length }}</b-badge>
                  <div class="d-inline-block">
                    <transition name="squish">
                      <netvs-icon v-if="collapse_states[2+index]"
                                  icon="navigation_list_marker"></netvs-icon>
                    </transition>
                    {{ record_type }} {{ $tc('system.record', 2) }}
                    <b-spinner small v-show="loading_states[2+index]" variant="primary"/>
                  </div>

                </b-list-group-item>
              </b-list-group>
            </Loading>
          </b-col>
          <b-col cols="10" cols-lg="12">
            <b-card class="shadow mb-4" no-body v-if="subnets.length > 0"
                    :id="collapse_states[1] ? 'reserved' : 'not-reserved'">
              <b-card-header v-b-toggle:reserved-collapse class="collapse-header">
                <b-badge v-if="reserved_addrs" variant="secondary" class="mr-2" pill>{{ reserved_addrs.length }}</b-badge>
                <h5 class="d-inline">{{ $t('views.dnsvs.bcd_records.reserved_addresses') }}</h5>
                <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
              </b-card-header>
              <b-card-body body-class="p-0">
                <b-collapse id="reserved-collapse" v-model="collapse_states[1]">
                  <b-pagination class="pt-3" v-if="loaded && reserved_addrs.length > per_page" :per_page="per_page"
                                align="center" v-model="current_page['_reserved']"
                                :total-rows="reserved_addrs.length"></b-pagination>
                  <b-table :items="reserved_addrs" :fields="reserved_addrs_list_fields" :per-page="per_page"
                           :current-page="current_page['_reserved']">
                    <template v-slot:cell(value)="data">
                      <code class="mr-2">{{ data.item.value }}</code>
                      <b-badge v-if="data.item.is_dhcp" class="mr-1">{{ $t('system.dhcp') }}</b-badge>
                      <IPAddressTypeBadge :subnets="subnets" :record_address="data.item.value" class="mr-1"/>
                    </template>
                  </b-table>
                  <b-pagination v-if="loaded && reserved_addrs.length > per_page" :per_page="per_page"
                                align="center" v-model="current_page['_reserved']"
                                :total-rows="reserved_addrs.length"></b-pagination>
                </b-collapse>
              </b-card-body>
            </b-card>
            <Loading :data="record_types">
              <DNSRecordTypeCard :loaded="loaded" :record_type="r" :key="'card_'+r"
                                 v-for="(r, index) in record_types" :records="records[r]"
                                 :reserved_addrs_by_ip="reserved_addrs_by_ip" :fqdns="fqdns" :bcd_has_v4="has_v4"
                                 :bcd_has_v6="has_v6" :bcd="bcd" :record_types_by_name="record_types_by_name"
                                 :user_possible_record_types="user_possible_record_types"
                                 :records_by_fqdn="records_by_fqdn"
                                 :edit_fqdn="editFQDN" :subnets="subnets"
                                 :full_edit_fqdn_reducer="full_edit_fqdn_reducer"
                                 :collapse_state.sync="collapse_states[2+index]"></DNSRecordTypeCard>
            </Loading>
          </b-col>
        </b-row>
        <DBEditor :presets="db_editor_presets" modal_id="edit_fqdn"
                  :object_title="object_title" :input_reducer="full_edit_fqdn_reducer"
                  object_function="update" object_fq_name="dns.fqdn" :old_data="db_editor_old_data"
                  :non_optionals_order="['value', 'description']"
                  :nullable_non_expert="['description']">
        </DBEditor>
      </template>
    </Loading>
  </div>
</template>

<script>
import RangeService from '@/api-services/dns_bcd.service'
import Loading from '../../components/Loading'
import DBEditor from '../../components/db-editor/APIObjectDBEditor.vue'
import apiutil from '@/util/apiutil'
import DNSRecordTypeCard from '@/components/DNSRecordTypeCard'
import IPAddressTypeBadge from '@/components/IPAddressTypeBadge.vue'

export default {
  name: 'BCDRecords',
  components: {
    IPAddressTypeBadge,
    DNSRecordTypeCard,
    Loading,
    DBEditor,
  },
  data() {
    return {
      toggle_all: {},
      records_checked: {},
      sort_by: {},
      sort_dir: {},
      filter: {},
      vlans: null,
      fqdns: null,
      user_possible_record_types: [],
      loaded: null,
      per_page: 50,
      current_page: {},
      full_create_record_reducer: undefined,
      full_edit_fqdn_reducer: undefined,
      db_editor_function: 'create',
      db_editor_presets: {},
      db_editor_old_data: {},
      bcd: null,
      subnets: null,
      reserved_addrs: null,
      reserved_addrs_by_ip: {},
      records: null,
      records_by_fqdn: {},
      record_types: null,
      record_types_by_name: null,
      loading_states: [false],
      collapse_states: [true],
      has_v4: false,
      has_v6: false,
      object_title: null,
    }
  },
  computed: {
    reservedAddrCount() {
      let count = 0
      for (let i = 0; i < this.subnets.length; i++) {
        count += this.subnets[i].dns_addr_rsv_count
      }
      return count
    },
    reserved_addrs_list_fields() {
      return [
        {
          label: this.$tc('system.address', 2),
          key: 'value',
          sortable: false // TODO: Sort function
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    $route() {
      this.bcd = null
      this.fetchData()
    }
  },
  methods: {
    canEnableBulkAction(rec_type) {
      let check_count = 0
      for (const b of this.records[rec_type]) {
        if (this.records_checked[rec_type + '_' + b.fqdn + '_' + b.data]) {
          check_count++
        }
      }
      return check_count > 0
    },
    editFQDN: function (item) {
      this.db_editor_function = 'update'
      this.db_editor_old_data = item
      this.db_editor_presets = item
      this.object_title = this.$t('system.fqdn') + ' ' + item.value
      this.$root.$emit('bv::show::modal', 'edit_fqdn')
    },
    async fetchData() {
      this.loaded = null
      const rangeResponse = await RangeService.getDetail(this.$store.state, this.$route.params.bcd_name)
      if (rangeResponse.data.bcd_list.length === 0) {
        await this.$router.push('/404')
        return
      }
      this.bcd = rangeResponse.data.bcd_list[0]
      this.subnets = rangeResponse.data.ip_subnet_list
      this.reserved_addrs = rangeResponse.data.ip_addr_list
      this.reserved_addrs_by_ip = apiutil.dict_by_value_of_array(this.reserved_addrs, 'value')
      this.vlans = rangeResponse.data.vlan_list
      this.has_v4 = this.has_v6 = false
      for (const sub of this.subnets) {
        this.has_v4 = this.has_v4 || sub.type === '4'
        this.has_v6 = this.has_v6 || sub.type === '6'
      }
      const recordQuery = await RangeService.getRecords(this.$store.state, this.$route.params.bcd_name)
      this.fqdns = apiutil.dict_by_value_of_array(recordQuery.data.fqdn_list, 'value')
      this.record_types_by_name = apiutil.dict_by_value_of_array(recordQuery.data.record_type_list, 'name')
      const types = recordQuery.data.record_inttype_list
      const selections = {}
      const fqdnSelections = {}
      for (const t of types) {
        selections[t.record_type] = { display_name: t.record_type }
      }
      for (const t of recordQuery.data.fqdn_type_list) {
        let disp_name = t.description
        disp_name += ' ['
        disp_name += t.is_nonterminal ? this.$t('views.dnsvs.bcd_records.non-terminal') : this.$t('views.dnsvs.bcd_records.terminal')
        disp_name += ']'
        fqdnSelections[t.name] = { display_name: disp_name }
      }
      this.full_edit_fqdn_reducer = {
        type: fqdnSelections
      }
      this.full_create_record_reducer = {
        type: selections
      }
      this.records = apiutil.dict_of_lists_by_value_of_array(recordQuery.data.record_list, 'type')
      this.records_by_fqdn = apiutil.dict_of_lists_by_value_of_array(recordQuery.data.record_list, 'fqdn')

      this.record_types = Object.keys(this.records)
      for (let i = 0; i < this.record_types.length; i++) {
        if (this.record_types[i] in this.records) {
          continue
        }
        this.records[this.record_types[i]] = []
      }
      for (const inttype of types) {
        if (!this.user_possible_record_types.includes(inttype.record_type)) {
          this.user_possible_record_types.push(inttype.record_type)
        }
        if (inttype.record_type in this.records) {
          continue
        }
        this.record_types.push(inttype.record_type)
        this.records[inttype.record_type] = []
      }
      this.record_types.sort()
      const self = this
      this.record_types = this.record_types.filter(t => (function (t) {
        if (t === 'A' && !self.has_v4) {
          return false
        }
        if (t === 'AAAA' && !self.has_v6) {
          return false
        }
        return true
      })(t))
      this.loaded = true
      this.$store.commit('setNavigationRefreshHandle', { gpk: this.bcd.gpk, objType: 'nd.bcd' })
    },

    scrollIntoView(e, index, id) {
      e.preventDefault()
      let target = document.getElementById(id)
      if (e.target.classList.contains('active') || e.target.parentElement.classList.contains('active')) {
        this.$set(this.collapse_states, index, false)
      } else {
        if (target) {
          const offset = window.innerWidth > 1200 ? 140 : 410
          window.scrollTo({ left: window.scrollX, top: target.offsetTop + offset, behavior: 'smooth' })
        } else {
          this.$set(this.loading_states, index, true)
          setTimeout(() => {
            target = document.getElementById('not-' + id)
            this.$set(this.collapse_states, index, true)
            const offset = window.innerWidth > 1200 ? 140 : 410
            setTimeout(() => window.scrollTo({
              left: window.scrollX,
              top: target.offsetTop + offset,
              behavior: 'smooth'
            }), 350)
            this.$set(this.loading_states, index, false)
          }, 0)
          // I know. It's disgusting.
        }
      }
    }
  }
}
</script>

<style scoped>
.list-group-item.active {
  color: white !important;
}

.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

.bottom-space {
  padding-bottom: 200px;
}

.squish-enter-active, .squish-leave-active {
  transition: width 250ms, padding-left 250ms, padding-right 250ms;
}

.squish-enter, .squish-leave-to {
  width: 0;
  padding-right: 0;
  padding-left: 0;
}

.collapse-header {
  z-index: 69;
}

</style>
