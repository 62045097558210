<template>
  <div id="MACAuth">
    <h1 class="my-4" v-if="entry || bcds == null">{{ $t('system.macauth') }}</h1>
    <b-alert show variant="info">
      <i18n path="views.macauth.mpsk_notice.text"
            tag="p" for="views.macauth.mpsk_notice">
        <template #request_link>
          <a target="_blank" href="https://www.scc.kit.edu/dienste/internet-of-things.php">{{
              $t('views.macauth.mpsk_notice.request_text')
            }}</a>
        </template>
      </i18n>
    </b-alert>
    <b-checkbox v-if="entry" :value="true" :unchecked-value="false" v-model="only_own" switch>
      {{ $t('views.macauth.show_only_own_bcds') }}
    </b-checkbox>
    <Loading :data="[clients_by_bcd, bcds]">
      <PaginatorList :items="bcds" v-if="entry">
        <template v-slot:item="card_data">
          <component
            :is="macAuthComponent"
            :bcd="card_data.item"
            :clients="card_data.item.name in clients_by_bcd ? clients_by_bcd[card_data.item.name] : []"
            :create-client="createClient"
            :delete-client="deleteClient"
            :edit-client="editClient"
            :show-q-r="show_qr"
          >
          </component>
        </template>
      </PaginatorList>
      <component v-else-if="bcds"
        :is="macAuthComponent"
        :bcd="bcds[0]"
        :clients="bcds[0].name in clients_by_bcd ? clients_by_bcd[bcds[0].name] : []"
        :create-client="createClient"
        :delete-client="deleteClient"
        :edit-client="editClient"
        :show-q-r="show_qr">
      </component>
      <DBEditor object_fq_name="macauth.client" :object_function="db_editor_object_function"
                modal_id="dbeditor_macauth" :old_data="db_editor_old_data" :presets="db_editor_presets"
                :non_optionals_order="['bcd_name', 'mac_addr', 'description', 'do_refresh_wpa_key']"
                :object_title="db_editor_object_title"
      ></DBEditor>
      <b-modal ok-only id="macauth-show-qr" v-if="qr_item" centered
               :title="$t('views.macauth.wifi_qr_code_for') + ' \'' + qr_item.mac_addr + '\''">
        <p class="text-center">
          <qrcode-vue v-if="qr_item" :value="'WIFI:S:KIT-IoT;T:WPA;P:'+encodeQr(qr_item.wpa_key)+';;'"></qrcode-vue>
        </p>
        <p><b>{{ $t('system.password') }}:</b></p>
        <CopyField :text="qr_item.wpa_key" code/>
      </b-modal>
    </Loading>
  </div>
</template>

<script>
import Loading from '../../components/Loading'
import MACAuth from '@/api-services/macauth.service'
import ApiUtil from '@/util/apiutil'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import transactionutil from '@/util/transactionutil'
import MACAuthService from '@/api-services.gen/macauth.client'
import PaginatorList from '@/components/PaginatorList'
import QrcodeVue from 'qrcode.vue'
import MacAuthCard from '../../components/MacAuthCard'
import MacAuthTable from '../../components/MacAuthTable'
import CopyField from '@/components/CopyField'

export default {
  name: 'macAuth',
  components: { MacAuthCard, MacAuthTable, PaginatorList, DBEditor, Loading, QrcodeVue, CopyField },
  data() {
    return {
      qr_item: null,
      only_own: true,
      db_editor_object_function: 'create',
      db_editor_old_data: {},
      db_editor_presets: {},
      db_editor_object_title: {},
      bcds: null,
      clients_by_bcd: null,
      filter_text: '',
      entry: true,
      waiting: false
    }
  },
  created() {
    this.fetchData()
  },
  props: {
    use_cards: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  watch: {
    only_own: {
      immediate: true,
      async handler() {
        await this.fetchData()
      }
    },
    $route() {
      this.fetchData()
    }
  },
  methods: {
    encodeQr(data) {
      return data.replaceAll('\\', '\\\\').replaceAll(';', '\\;').replaceAll(',', '\\,').replaceAll('"', '\\"').replaceAll(':', '\\:')
    },
    async fetchData() {
      this.entry = !('bcd_name' in this.$route.params)
      this.bcds = null
      this.clients_by_bcd = null
      let response
      if (this.entry) {
        response = await MACAuth.list(this.$store.state, this.only_own)
      } else {
        response = await MACAuth.get(this.$store.state, this.$route.params.bcd_name)
      }
      this.clients_by_bcd = ApiUtil.dict_of_lists_by_value_of_array(response.data.client_list, 'bcd_name')
      this.bcds = response.data.bcd_list
    },
    show_qr(item) {
      this.qr_item = item
      this.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'macauth-show-qr')
      })
    },
    createClient(bcd) {
      this.db_editor_object_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = { bcd_name: bcd.name }
      this.db_editor_object_title = null
      this.$root.$emit('bv::show::modal', 'dbeditor_macauth')
    },
    editClient(item) {
      this.db_editor_object_function = 'update'
      this.db_editor_old_data = item
      this.db_editor_presets = item
      this.db_editor_object_title = item.mac_addr + ' ' + this.$t('views.macauth.in_bcd') + ' ' + item.bcd_name
      this.$root.$emit('bv::show::modal', 'dbeditor_macauth')
    },
    deleteClient(item) {
      const ta = transactionutil.generateDeleteElement('macauth.client', MACAuthService.deleteParamsList(), item, item.mac_addr + ' ' + this.$t('views.macauth.in_bcd') + ' ' + item.bcd_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    }
  },
  computed: {
    macAuthComponent() {
      return this.use_cards ? MacAuthCard : MacAuthTable
    }
  }
}
</script>

<style>
.btn-group.fullwidth {
  display: flex;
}

.fullwidth .btn {
  flex: 1
}

.collapse-icon {
  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}
</style>
