<template>
  <b-card no-body class="mb-4 shadow">
    <template v-slot:header>
      <b-row>
        <b-col lg="3">
          <h4>
            {{ bcd.name }}
            <b-badge variant="success">
              {{ $tc('components.mac_auth_card.clients', clients.length, { 'count': clients.length }) }}
            </b-badge>
          </h4>
          <p class="text-muted">{{ $t('components.mac_auth_card.broadcastdomain') }}</p>
        </b-col>
        <b-col>
          <div v-if="bcd.description === '' || bcd.description == null">
            {{ $t('components.mac_auth_card.no_description') }}
          </div>
          <div v-else>{{ bcd.description }}</div>
          <p class="text-muted">{{ $t('system.description') }}</p>
        </b-col>
      </b-row>
    </template>
    <b-button block squared variant="outline-secondary" v-b-toggle="bcd.name + '-collapse'">
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse :id="bcd.name + '-collapse'">
      <MacAuthTable
        :bcd="bcd"
        :clients="clients"
        :createClient="createClient"
        :deleteClient="deleteClient"
        :editClient="editClient"
        :showQR="showQR"
      />
    </b-collapse>
  </b-card>
</template>

<script>

import dateutil from '../util/dateutil'
import MacAuthTable from './MacAuthTable.vue'

export default {
  name: 'MacAuthCard',
  components: { MacAuthTable },
  computed: {
    macauth_fields() {
      return [
        {
          key: 'description',
          label: this.$t('system.description')
        },
        {
          key: 'mac_addr',
          label: this.$t('system.mac_address'),
          sortable: true
        },
        {
          key: 'wpa_key',
          label: this.$t('components.mac_auth_card.wpa2_key')
        },
        {
          key: 'last_login_date',
          label: this.$t('components.mac_auth_card.last_login'),
          sortable: true
        },
        {
          key: 'last_login_node_info',
          label: this.$t('components.mac_auth_card.last_login_node'),
          sortable: true
        },
        'buttons'
      ]
    }
  },
  props: {
    bcd: {
      type: Object,
      required: true
    },
    clients: {
      type: Array,
      required: true
    },
    createClient: {
      type: Function,
      required: true
    },
    deleteClient: {
      type: Function,
      required: true
    },
    editClient: {
      type: Function,
      required: true
    },
    showQR: {
      type: Function,
      required: true
    }
  },
  methods: {
    formatDate(value) {
      if (value == null) {
        return 'N/A'
      }
      return dateutil.format_date(new Date(Date.parse(value)))
    }
  }
}
</script>

<style scoped>

</style>
