<template>
  <li>
    <b-link class="font-weight-bold" :to="'/org/ou/'+ou.short_name">{{ ou.name }} ({{ ou.short_name }})</b-link>
    <b-badge class="ml-1" v-if="ou.short_name in direct_bcd2ous_by_ou" variant="primary">
      {{ $t('components.ou_mgr_tree_entry.bcd_assigned_here') }}
    </b-badge>
    <b-link class="ml-1"
            v-if="mgrs_mails.length > 0"
            :href="`mailto:${mgrs_mails.join(';')}?subject= ${current_bcd ?
                                      $t('views.dnsvs.bcd_records.ou_bcd_mail_template',
                                      {ou_name: ou.short_name, name: current_bcd.name}) :
                                      $t('views.dnsvs.bcd_records.ou_mail_template',
                                      {ou_name: ou.short_name})}`">
      <netvs-icon icon="mail"></netvs-icon>
    </b-link>
    <ul>
      <li v-for="mgr in parent_oe2mgr[ou.short_name]" v-bind:key="mgr.mgr_login_name">
        <MgrLine :mgr="parent_oe_mgr[mgr.mgr_login_name]"/>
      </li>
      <OUMgrTreeEntry v-for="child_ou in ous_by_parent[ou.short_name]" :key="child_ou.short_name" :ou="child_ou"
                      :ous_by_parent="ous_by_parent" :parent_oe2mgr="parent_oe2mgr" :parent_oe_mgr="parent_oe_mgr"
                      :direct_bcd2ous_by_ou="direct_bcd2ous_by_ou" :current_bcd="current_bcd"/>
    </ul>
  </li>
</template>

<script>
/**
 * @displayName OUMgrTreeEntry
 * @description Component for recursive rendering of OU manager tree
 */
import OUMgrTreeEntry from '@/components/OUMgrTreeEntry'
import MgrLine from '@/components/MgrLine.vue'

export default {
  name: 'OUMgrTreeEntry',
  components: {MgrLine, OUMgrTreeEntry},
  props: {
    ou: {
      type: Object,
      required: true
    },
    ous_by_parent: {
      type: Object,
      required: true
    },
    parent_oe2mgr: {
      type: Object,
      required: true
    },
    parent_oe_mgr: {
      type: Object,
      required: true
    },
    direct_bcd2ous_by_ou: {
      type: Object,
      required: true
    },
    current_bcd: {
      type: Object,
      required: false
    }
  },
  methods: {
    mgrs_mail(user_name) {
      return `${this.parent_oe_mgr[user_name].first_name} ${this.parent_oe_mgr[user_name].last_name}<${this.parent_oe_mgr[user_name].email}>`
    }
  },
  computed: {
    mgrs_mails() {
      const mails = []
      if (this.parent_oe2mgr[this.ou.short_name]) {
        for (const mgr of this.parent_oe2mgr[this.ou.short_name]) {
          if (this.parent_oe_mgr[mgr.mgr_login_name].email) {
            mails.push(this.mgrs_mail(mgr.mgr_login_name))
          }
        }
      }
      if (mails.length > 0) {
        if (this.ous_by_parent[this.ou.short_name]) {
          for (const child_ou of this.ous_by_parent[this.ou.short_name]) {
            if (this.parent_oe2mgr[child_ou.short_name]) {
              for (const mgr of this.parent_oe2mgr[child_ou.short_name]) {
                if (this.parent_oe_mgr[mgr.mgr_login_name].email) {
                  mails.push(this.mgrs_mail(mgr.mgr_login_name))
                }
              }
            }
          }
        }
        return [...new Set(mails)]
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
